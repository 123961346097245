import React from "react";
import { Trash2 } from "lucide-react";
import styled from "styled-components";
import { FractionSelect } from "./FractionSelect";

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 20px;
  width: 100%;
  justify-content: flex-start;
`;

const StyledLabel = styled.label`
  font-weight: 500;
  white-space: nowrap;
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  width: auto;
  flex-shrink: 0;
  min-width: 0;
`;

const NumberInput = styled.input`
  width: 80px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const UnitText = styled.span`
  color: #666;
`;

/*
const DeleteContainer = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
`;
*/

const IconButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #666;
  border: none;
  background: none;
  cursor: pointer;
  padding: 4px;
  &:hover {
    color: #ef4444;
  }
`;

export function TrackLengthInput({ label, wholeValue, fractionValue, onWholeChange, onFractionChange, onDelete }) {
  return (
    <Container>
      <StyledLabel>{label}:</StyledLabel>
      <InputContainer>
        <NumberInput type="number" min="0" value={wholeValue} onChange={(e) => onWholeChange(Number(e.target.value))} />
        <span>-</span>
        <FractionSelect value={fractionValue} onChange={onFractionChange} />
        <UnitText>inches</UnitText>
      </InputContainer>
      {onDelete ? (
        <IconButton type="button" onClick={onDelete} aria-label="Remove track">
          <Trash2 size={14} />
        </IconButton>
      ) : null}
    </Container>
  );
}
