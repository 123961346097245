import React from "react";
import styled from "styled-components";
import FasTrackCalc from "./trainComponents/FasTrackCalc";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #f5f5f5;

  @media (max-width: 900px) {
    padding: 10px;
  }
`;

function TrainTools() {
  return (
    <Container>
      <FasTrackCalc />
    </Container>
  );
}

export default TrainTools;
