/* eslint-disable react/no-unescaped-entities */
import React, { useState } from "react";
import styled from "styled-components";
import { AlertCircle } from "lucide-react";
import { Card, Title, FormGroup, Button } from "./commonStyles";
import { TrackLengthInput } from "./TrackLengthInput";
import { SolutionsTable } from "./SolutionsTable";
import { fractionToDecimal, findTrackCombinations, groupSolutionsByAdjustment } from "./trackCalculations";

const TrackContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 1rem;
`;

const TrackInputWrapper = styled.div`
  flex: 1;
`;

const ResultsSection = styled.div`
  margin-top: 1.5rem;
`;

const NoSolutionsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 1rem;
  margin-top: 1rem;
  background-color: #fff8e7;
  border: 1px solid #ffecc7;
  border-radius: 0.375rem;
  color: #92400e;
`;

const CalculateButton = styled(Button)`
  width: 100%;
  margin-top: 1rem;
`;

const MessageText = styled.p`
  margin: 0;
`;

const HelpText = styled.div`
  margin-bottom: 1.5rem;
  line-height: 1.5;
  color: #4a5568;
`;

const HelpParagraph = styled.p`
  margin-bottom: 0.75rem;
  &:last-child {
    margin-bottom: 0;
  }
`;

const Tip = styled(HelpParagraph)`
  color: #718096;
  font-size: 0.875rem;
  font-style: italic;
`;

function NoSolutionsMessage() {
  return (
    <NoSolutionsWrapper>
      <AlertCircle size={20} />
      <MessageText>
        No solutions found within the specified tolerance. Try adjusting the tolerance or track length.
      </MessageText>
    </NoSolutionsWrapper>
  );
}

function TrackCalculator() {
  const [tracks, setTracks] = useState([
    {
      id: crypto.randomUUID(),
      wholeLength: 0,
      fractionLength: 0,
      enabled: true,
    },
  ]);

  const [tolerance, setTolerance] = useState({
    whole: 0,
    fraction: 4,
  });

  const [groupedResults, setGroupedResults] = useState(null);
  const [isCalculating, setIsCalculating] = useState(false);

  const handleTrackChange = (trackId, field, value) => {
    setTracks((prev) => prev.map((track) => (track.id === trackId ? { ...track, [field]: value } : track)));
    setGroupedResults(null); // Clear results when any track value changes
  };

  const handleAddTrack = () => {
    setTracks((prev) => [
      ...prev,
      {
        id: crypto.randomUUID(),
        wholeLength: 0,
        fractionLength: 0,
        enabled: true,
      },
    ]);
    setGroupedResults(null); // Clear results when adding a track
  };

  const handleRemoveTrack = (trackId) => {
    setTracks((prev) => prev.filter((track) => track.id !== trackId));
    setGroupedResults(null); // Clear results when removing a track
  };

  const handleCalculate = () => {
    setIsCalculating(true);

    const enabledTracks = tracks.filter((track) => track.enabled);
    const targetLengths = enabledTracks.map((track) => fractionToDecimal(track.wholeLength, track.fractionLength));

    const toleranceValue = fractionToDecimal(tolerance.whole, tolerance.fraction);

    const trackResults = targetLengths.map((length) => findTrackCombinations(length, toleranceValue));

    const grouped = groupSolutionsByAdjustment(trackResults, targetLengths);

    setGroupedResults(grouped);
    setIsCalculating(false);
  };

  return (
    <Card>
      <Title>Lionel FasTrack Calculator</Title>

      <HelpText>
        <HelpParagraph>
          Calculate the optimal combinations of Lionel O-gauge FasTrack® pieces to achieve your desired length. Enter
          your target length and acceptable tolerance, and the calculator will show all possible combinations using
          standard pieces (1⅜", 1¾", 3", 4½", 5", 10", and 30").
        </HelpParagraph>
        <HelpParagraph>
          Building unequal length parallel tracks? Add additional track lengths and see solutions for each track.
          Results are grouped by their adjustment from your target length(s), showing the pieces needed for each
          solution.
        </HelpParagraph>
        <Tip>
          Start with a wider tolerance (±¼") if you need more options, then decrease it if you need exact lengths.
          Solutions are ordered by piece count, so the most efficient combinations appear first.
        </Tip>
      </HelpText>

      <hr />
      <TrackLengthInput
        label="Tolerance (±)"
        wholeValue={tolerance.whole}
        fractionValue={tolerance.fraction}
        onWholeChange={(value) => setTolerance((prev) => ({ ...prev, whole: value }))}
        onFractionChange={(value) => setTolerance((prev) => ({ ...prev, fraction: value }))}
      />
      <hr />
      {tracks.map((track, index) => (
        <TrackContainer key={track.id}>
          <TrackInputWrapper>
            <TrackLengthInput
              label={`Track ${index + 1} Length`}
              wholeValue={track.wholeLength}
              fractionValue={track.fractionLength}
              onWholeChange={(value) => handleTrackChange(track.id, "wholeLength", value)}
              onFractionChange={(value) => handleTrackChange(track.id, "fractionLength", value)}
              onDelete={tracks.length > 1 ? () => handleRemoveTrack(track.id) : null}
            />
          </TrackInputWrapper>
          {index === tracks.length - 1 && (
            <FormGroup>
              <Button onClick={handleAddTrack}>Add Parallel Track</Button>
            </FormGroup>
          )}
        </TrackContainer>
      ))}

      <CalculateButton onClick={handleCalculate} disabled={isCalculating}>
        {isCalculating ? "Calculating..." : "Calculate Solutions"}
      </CalculateButton>

      {groupedResults && groupedResults.length > 0 ? (
        <ResultsSection>
          <SolutionsTable groupedSolutions={groupedResults} tracks={tracks} />
        </ResultsSection>
      ) : (
        groupedResults && <NoSolutionsMessage />
      )}
    </Card>
  );
}

export default TrackCalculator;
