/* eslint-disable react/no-array-index-key */
import React from "react";
import styled from "styled-components";
import { TRACK_LENGTHS } from "./constants";
import { decimalToFraction } from "./trackCalculations";

const TableContainer = styled.div`
  overflow-x: auto;
  margin-top: 16px;
`;

const StyledSolutionsTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  background: white;
  margin-bottom: 2rem;
`;

const TableHeader = styled.th`
  padding: 8px;
  border: 1px solid #e0e0e0;
  background: #f8f9fa;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  &:first-child {
    text-align: left;
  }
`;

const TableCell = styled.td`
  padding: 8px;
  border: 1px solid #e0e0e0;
  text-align: center;
  &:first-child {
    text-align: left;
  }
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background: #f8f9fa;
  }
`;

// Add a border top to rows that start a new track section
const TrackRow = styled(TableRow)`
  ${(props) =>
    props.$isFirstInTrack
      ? `
    border-top: 2px solid #666;
  `
      : ""}
`;

const AdjustmentGroupTitle = styled.h3`
  margin-top: 2rem;
  margin-bottom: 1rem;
  font-size: 1.25rem;
  font-weight: 500;
`;

export function SolutionsTable({ groupedSolutions, tracks }) {
  const getPieceCount = (solution) => {
    return solution.pieces.length;
  };

  const getTotal = (solution) => {
    return solution.pieces.reduce((sum, length) => sum + length, 0);
  };

  const getPieceCountByLength = (solution, trackLength) => {
    return solution.pieces.filter((length) => length === trackLength).length;
  };

  const filterMinimumPieceSolutions = (trackSolutions, n = 2) => {
    if (!trackSolutions || trackSolutions.length === 0) return [];

    const pieceCounts = [...new Set(trackSolutions.map(getPieceCount))].sort((a, b) => a - b);
    const effectiveN = Math.min(n, pieceCounts.length);
    const targetCounts = pieceCounts.slice(0, effectiveN);

    return trackSolutions.filter((solution) => {
      const count = getPieceCount(solution);
      return targetCounts.includes(count);
    });
  };

  const getTrackLengthDisplay = (track) => {
    return `${track.wholeLength}${track.fractionLength ? ` ${track.fractionLength}/8` : ""}`;
  };

  const getTotalMinimumPieces = (trackSolutions) => {
    return trackSolutions.reduce((sum, { filteredSolutions }) => {
      if (filteredSolutions.length === 0) return sum;
      const minPieces = Math.min(...filteredSolutions.map(getPieceCount));
      return sum + minPieces;
    }, 0);
  };

  // Sort the grouped solutions to put exact matches (adjustment = 0) first
  const sortedGroupedSolutions = [...groupedSolutions].sort((a, b) => {
    if (a.adjustment === 0) return -1;
    if (b.adjustment === 0) return 1;
    return a.adjustment - b.adjustment;
  });

  return (
    <div>
      {sortedGroupedSolutions.map(({ adjustment, solutions: adjustmentSolutions }, groupIndex) => {
        // Collect all valid solutions across tracks
        const allTrackSolutions = adjustmentSolutions
          .map((trackSolutions, trackIndex) => {
            const track = tracks[trackIndex];
            if (!track?.enabled) return null;

            const filteredSolutions = filterMinimumPieceSolutions(trackSolutions);
            if (filteredSolutions.length === 0) return null;

            return {
              trackIndex,
              filteredSolutions,
              track,
            };
          })
          .filter(Boolean); // Remove null entries

        if (allTrackSolutions.length === 0) return null;

        const totalMinPieces = getTotalMinimumPieces(allTrackSolutions);

        return (
          <div key={`group-${groupIndex}`}>
            <AdjustmentGroupTitle>
              {adjustment === 0
                ? `Exact Match - minimum ${totalMinPieces} pieces`
                : `Adjustment: ${adjustment > 0 ? "+" : ""}${decimalToFraction(adjustment)} - minimum ${totalMinPieces} pieces`}
            </AdjustmentGroupTitle>

            <TableContainer>
              <StyledSolutionsTable>
                <thead>
                  <tr>
                    <TableHeader>Track (Length)</TableHeader>
                    <TableHeader>Adjusted Length</TableHeader>
                    {TRACK_LENGTHS.map((length) => (
                      <TableHeader key={length}>{decimalToFraction(length)}</TableHeader>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {allTrackSolutions.map(({ trackIndex, filteredSolutions, track }) =>
                    filteredSolutions.map((solution, solutionIndex) => (
                      <TrackRow key={`${trackIndex}-${solutionIndex}`} $isFirstInTrack={solutionIndex === 0}>
                        <TableCell>
                          {solutionIndex === 0 ? `Track ${trackIndex + 1} (${getTrackLengthDisplay(track)}″)` : ""}
                        </TableCell>
                        <TableCell>{decimalToFraction(getTotal(solution))}</TableCell>
                        {TRACK_LENGTHS.map((length) => (
                          <TableCell key={length}>{getPieceCountByLength(solution, length) || ""}</TableCell>
                        ))}
                      </TrackRow>
                    )),
                  )}
                </tbody>
              </StyledSolutionsTable>
            </TableContainer>
          </div>
        );
      })}
    </div>
  );
}
