import React from "react";
import { Select } from "./commonStyles";
import { FRACTIONS } from "./constants";

export function FractionSelect({ value, onChange }) {
  return (
    <Select value={value} onChange={(e) => onChange(Number(e.target.value))}>
      {FRACTIONS.map(({ value: fractionValue, label }) => (
        <option key={fractionValue} value={fractionValue}>
          {label}
        </option>
      ))}
    </Select>
  );
}
