/* eslint-disable no-continue */

// 1. Utility: Convert fraction in eighths to decimal inches
export function fractionToDecimal(whole, fraction) {
  return whole + fraction / 8;
}

// 2. Greatest Common Divisor (utility for fraction simplification)
function gcd(ain, bin) {
  let a = Math.abs(ain);
  let b = Math.abs(bin);
  while (b) {
    const temp = b;
    b = a % b;
    a = temp;
  }
  return a;
}

// 3. Simplify fraction (numerator/denominator)
function simplifyFraction(numerator, denominator) {
  const divisor = gcd(numerator, denominator);
  return [numerator / divisor, denominator / divisor];
}

// 4. Convert decimal inches to fraction of an inch (in eighths)
export function decimalToFraction(decimal) {
  const isNegative = decimal < 0;
  const absDecimal = Math.abs(decimal);
  const whole = Math.floor(absDecimal);
  const fraction = Math.round((absDecimal - whole) * 8);

  // Handle cases where rounding makes fraction = 8
  if (fraction === 8) {
    return isNegative ? `${-(whole + 1)}"` : `${whole + 1}"`;
  }

  if (fraction === 0) {
    return isNegative ? `${-whole}"` : `${whole}"`;
  }

  // Convert from 8ths to simpler fractions where possible
  const [num, den] = simplifyFraction(fraction, 8);

  if (whole === 0) {
    return isNegative ? `-${num}/${den}"` : `${num}/${den}"`;
  }

  return isNegative ? `-${whole}-${num}/${den}"` : `${whole}-${num}/${den}"`;
}

// Track piece lengths in eighths of an inch
const DENOM_SMALL = [11, 14, 24, 36, 40, 80]; // e.g., up to 10" (80/8)
const DENOM_BIG = 240; // 30" piece, in eighths
const MAX_SMALL = 239; // One less than our "big" piece

/**
 * 5. Precompute which lengths <= MAX_SMALL are possible with small pieces.
 *    dpSmall[x] is true if x leftover can be formed from DENOM_SMALL pieces.
 */
function buildDpSmall() {
  const dp = new Array(MAX_SMALL + 1).fill(false);
  dp[0] = true;

  for (let x = 1; x <= MAX_SMALL; x++) {
    for (const d of DENOM_SMALL) {
      if (x - d >= 0 && dp[x - d]) {
        dp[x] = true;
        break;
      }
    }
  }
  return dp;
}
const dpSmall = buildDpSmall();

/**
 * 6. Find all combinations of small pieces that sum to `target`.
 *    Uses backtracking to enumerate combos (for final solution details).
 */
function findSmallCombinations(target, start = 0, current = []) {
  // If we hit exactly 0, we've formed a valid combo
  if (target === 0) {
    return [current];
  }
  // If negative or not solvable, no solutions
  if (target < 0 || !dpSmall[target]) {
    return [];
  }

  const results = [];
  for (let i = start; i < DENOM_SMALL.length; i++) {
    const piece = DENOM_SMALL[i];
    if (piece > target) continue;
    // Recurse with same `i` to allow unlimited usage of the same piece
    results.push(...findSmallCombinations(target - piece, i, [...current, piece]));
  }
  return results;
}

/**
 * 7. **Refactored** findTrackCombinations:
 *    - For each length in [target - tolerance, target + tolerance],
 *      compute numBig in one shot => leftover in one shot =>
 *      only if leftover is unsolvable do we do the special "tweener" logic.
 *    - Then we get small piece combos for whichever leftover we use.
 */
export function findTrackCombinations(targetLengthInches, toleranceInches, numLevelsPerAdjustment = 2) {
  // Convert inches to eighths
  const targetEighths = Math.round(targetLengthInches * 8);
  const toleranceEighths = Math.round(toleranceInches * 8);

  // Map<adjustment (in inches), Map<pieceCount, { pieces, adjustment }[]>>
  const solutionsByAdjustment = new Map();

  // For each length in the tolerance range
  for (
    let lengthEighths = targetEighths - toleranceEighths;
    lengthEighths <= targetEighths + toleranceEighths;
    lengthEighths++
  ) {
    if (lengthEighths <= 0) continue;

    // Adjustment in inches (just for storing in final solution)
    const adjustment = (lengthEighths - targetEighths) / 8;

    // Compute how many big pieces to use in "one shot"
    let numBig = Math.floor(lengthEighths / DENOM_BIG);
    let leftover = lengthEighths - numBig * DENOM_BIG;
    let tweener = false;

    // If leftover is not solvable and we have at least 1 big piece, do the "tweener" fix:
    //   i.e. remove one big piece ( -240 ) and add 80 leftover => net leftover += 80, numBig--
    // Only do this once, as that’s the single “exception” scenario.
    if (leftover >= 0 && leftover <= MAX_SMALL && dpSmall[leftover]) {
      // leftover is solvable as-is
    } else if (numBig > 0) {
      // Try leftover+80 by removing one big piece
      const leftoverTweener = leftover + 80;
      if (leftoverTweener >= 0 && leftoverTweener <= MAX_SMALL && dpSmall[leftoverTweener]) {
        leftover = leftoverTweener;
        numBig--;
        tweener = true;
      }
    }

    // If leftover is still valid and solvable, find all small combos
    if (leftover >= 0 && leftover <= MAX_SMALL && dpSmall[leftover]) {
      const smallCombos = findSmallCombinations(leftover);

      // Make the array of big pieces
      // (numBig of them, each 240 eighths)
      const bigPieces = Array(numBig).fill(DENOM_BIG);

      // If we did the tweener, conceptually we replaced one big piece with leftover+80.
      // But physically, to keep track of the total piece list:
      //   - We have (numBig) standard big pieces
      //   - Plus we used an extra 80 leftover in place of that removed big piece
      // For the final solution, you can represent that leftover “extra 80” in many ways.
      // Here we just keep bigPieces as is, then add no special piece for “tweener”,
      // because leftover already includes the +80. Alternatively, you could track
      // an “80” piece explicitly if that helps with a parts list.

      for (const smallPieces of smallCombos) {
        // Build the final solution's piece array
        const solutionPieces = [...bigPieces, ...smallPieces, ...(tweener ? [80, 80] : [])];

        // If you prefer to mark the “tweener” scenario as a separate piece (80),
        // you could do: if (tweener) solutionPieces.push(80);

        const solution = {
          pieces: solutionPieces,
          adjustment: Number(adjustment.toFixed(3)),
        };

        // Store in solutionsByAdjustment => pieceCount
        if (!solutionsByAdjustment.has(adjustment)) {
          solutionsByAdjustment.set(adjustment, new Map());
        }
        const solutionsForAdjustment = solutionsByAdjustment.get(adjustment);

        const pieceCount = solutionPieces.length;
        if (!solutionsForAdjustment.has(pieceCount)) {
          solutionsForAdjustment.set(pieceCount, []);
        }
        solutionsForAdjustment.get(pieceCount).push(solution);
      }
    }
  }

  // Sort adjustments by closeness to zero and pieceCount ascending
  const finalSolutions = [];
  const adjustments = Array.from(solutionsByAdjustment.keys()).sort((a, b) => Math.abs(a) - Math.abs(b));

  for (const adj of adjustments) {
    const solutionsForAdjustment = solutionsByAdjustment.get(adj);
    const pieceCounts = Array.from(solutionsForAdjustment.keys()).sort((a, b) => a - b);
    // Keep only the first `numLevelsPerAdjustment` piece-count sizes
    const levelsToKeep = pieceCounts.slice(0, numLevelsPerAdjustment);

    for (const pieceCount of levelsToKeep) {
      const solutions = solutionsForAdjustment.get(pieceCount);
      // Convert piece lengths from eighths to inches for the final result
      const convertedSolutions = solutions.map((sol) => ({
        pieces: sol.pieces.map((p) => p / 8),
        adjustment: sol.adjustment,
      }));
      finalSolutions.push(...convertedSolutions);
    }
  }

  return finalSolutions;
}

/**
 * 8. Group solutions by a common adjustment across multiple tracks
 *    (unchanged logic from your original code)
 */
export function groupSolutionsByAdjustment(trackResults) {
  // Create a map of adjustments to arrays of solutions
  const solutionGroups = new Map();

  // First track's solutions define possible adjustments
  trackResults[0].forEach((solution) => {
    const { adjustment } = solution;

    // Check if all tracks have solutions with this adjustment
    const validForAllTracks = trackResults.every((trackSolutions) =>
      trackSolutions.some((s) => Math.abs(s.adjustment - adjustment) < 0.001),
    );

    if (validForAllTracks) {
      // Get matching solutions for each track
      const matchingSolutions = trackResults.map((trackSolutions) =>
        trackSolutions.filter((s) => Math.abs(s.adjustment - adjustment) < 0.001),
      );

      solutionGroups.set(adjustment, matchingSolutions);
    }
  });

  return Array.from(solutionGroups.entries()).map(([adjustment, solutions]) => ({
    adjustment,
    solutions,
  }));
}
