import React from "react";
import styled from "styled-components";

function LinkedInIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      width="24"
      height="24"
      aria-label="LinkedIn"
    >
      <path d="M20.5 2h-17A1.5 1.5 0 002 3.5v17A1.5 1.5 0 003.5 22h17a1.5 1.5 0 001.5-1.5v-17A1.5 1.5 0 0020.5 2zM8 19H5v-9h3zM6.5 8.25A1.75 1.75 0 118.3 6.5a1.78 1.78 0 01-1.8 1.75zM19 19h-3v-4.74c0-1.42-.6-1.93-1.38-1.93A1.74 1.74 0 0013 14.19a.66.66 0 000 .14V19h-3v-9h2.9v1.3a3.11 3.11 0 012.7-1.4c1.55 0 3.36.86 3.36 3.66z" />
    </svg>
  );
}

function BlueskyIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 600 530"
      fill="currentColor"
      width="24"
      height="24"
      aria-label="Bluesky"
    >
      <path d="m135.72 44.03c66.496 49.921 138.02 151.14 164.28 205.46 26.262-54.316 97.782-155.54 164.28-205.46 47.98-36.021 125.72-63.892 125.72 24.795 0 17.712-10.155 148.79-16.111 170.07-20.703 73.984-96.144 92.854-163.25 81.433 117.3 19.964 147.14 86.092 82.697 152.22-122.39 125.59-175.91-31.511-189.63-71.766-2.514-7.3797-3.6904-10.832-3.7077-7.8964-0.0174-2.9357-1.1937 0.51669-3.7077 7.8964-13.714 40.255-67.233 197.36-189.63 71.766-64.444-66.128-34.605-132.26 82.697-152.22-67.108 11.421-142.55-7.4491-163.25-81.433-5.9562-21.282-16.111-152.36-16.111-170.07 0-88.687 77.742-60.816 125.72-24.795z" />
    </svg>
  );
}

const StyledFooter = styled.footer`
  position: relative;
  background-color: #333;
  color: #fff;
  padding: 20px;

  p {
    margin: 0;
    text-align: center;

    @media (max-width: 768px) {
      text-align: left;
      padding-right: 80px; /* Add space for icons */
    }
  }

  .social-icons {
    position: absolute;
    bottom: 18px;
    right: 20px;
    display: flex;
    gap: 12px;

    a {
      color: #fff;
      transition:
        color 0.3s,
        background-color 0.3s;
      border-radius: 4px;
      height: 24px;
      display: flex;
      align-items: center;

      &:hover {
        background-color: #fff;

        &.linkedin {
          color: #0a66c2; /* LinkedIn blue */
        }

        &.bluesky {
          color: #1185fe; /* Bluesky blue */
        }
      }
    }
  }
`;

function Footer() {
  return (
    <StyledFooter>
      <p>&copy; {new Date().getFullYear()} Precipex. All rights reserved.</p>
      <div className="social-icons">
        <a
          href="https://bsky.app/profile/precipex.com"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Bluesky"
          className="bluesky"
        >
          <BlueskyIcon />
        </a>
        <a
          href="https://www.linkedin.com/company/precipex"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="LinkedIn"
          className="linkedin"
        >
          <LinkedInIcon />
        </a>
      </div>
    </StyledFooter>
  );
}

export default Footer;
