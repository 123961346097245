export const TRACK_LENGTHS = [
  30, // 30"
  10, // 10"
  5, // 5"
  4.5, // 4-1/2"
  3, // 3"
  1.75, // 1-3/4"
  1.375, // 1-3/8"
];

export const FRACTIONS = [
  { value: 0, label: "0" },
  { value: 1, label: "1/8" },
  { value: 2, label: "1/4" },
  { value: 3, label: "3/8" },
  { value: 4, label: "1/2" },
  { value: 5, label: "5/8" },
  { value: 6, label: "3/4" },
  { value: 7, label: "7/8" },
];
