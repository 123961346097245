import React, { useState, useEffect, useRef } from "react";
import ReactGA from "react-ga4";
import styled from "styled-components";
import { marked } from "marked";
import { useLocation, Link } from "react-router-dom";
import ChatBot from "./Chat";

import paper20240527 from "../papers/paper_20240527";
import paper20240603 from "../papers/paper_20240603";
import paper20240610 from "../papers/paper_20240610";
import paper20240617 from "../papers/paper_20240617";
import paper20240624 from "../papers/paper_20240624";
import paper20240701 from "../papers/paper_20240701";
import paper20240708 from "../papers/paper_20240708";
import paper20240715 from "../papers/paper_20240715";
import paper20240722 from "../papers/paper_20240722";
import paper20240729 from "../papers/paper_20240729";
import paper20240805 from "../papers/paper_20240805";
import paper20240812 from "../papers/paper_20240812";
import paper20240819 from "../papers/paper_20240819";
import paper20240826 from "../papers/paper_20240826";
import paper20240902 from "../papers/paper_20240902";
import paper20240909 from "../papers/paper_20240909";
import paper20240916 from "../papers/paper_20240916";
import paper20240923 from "../papers/paper_20240923";
import paper20240930 from "../papers/paper_20240930";
import paper20241007 from "../papers/paper_20241007";
import paper20241014 from "../papers/paper_20241014";
import paper20241021 from "../papers/paper_20241021";
import paper20241028 from "../papers/paper_20241028";
import paper20241104 from "../papers/paper_20241104";
import paper20241111 from "../papers/paper_20241111";
import paper20241118 from "../papers/paper_20241118";
import paper20241125 from "../papers/paper_20241125";
import paper20241202 from "../papers/paper_20241202";
import paper20241209 from "../papers/paper_20241209";
import paper20241216 from "../papers/paper_20241216";
import paper20241230 from "../papers/paper_20241230";
import paper20250106 from "../papers/paper_20250106";
import paper20250113 from "../papers/paper_20250113";
import paper20250121 from "../papers/paper_20250121";

const showChat = true;

const PageContainer = styled.div`
  width: 100%;
  background-color: #f5f5f5;
  position: relative;
`;

const BlogContainer = styled.div`
  max-width: 1600px;
  margin: 0 auto;
  padding: 20px;
  display: flex;
  justify-content: center;

  @media (max-width: 800px) {
    padding: 0;
    transform: none;
  }
`;

const Content = styled.div`
  width: 800px;
  padding: 20px;
  background-color: #ffffff;
  border-left: 1px solid #ddd;
  margin-left: 15px;

  @media (max-width: 900px) {
    width: 100%;
    margin-left: 0;
    border-left: none;
  }

  .intro {
    border: 1px solid #ddd;
    padding: 10px 20px;
    background-color: #f9fafb;
  }

  h1 {
    text-align: center;
  }

  .post-teaser {
    padding-top: 30px;
    padding-bottom: 20px;
    font-size: larger;
    font-weight: 400;

    @media (min-width: 768px) {
      padding-left: 40px;
      padding-right: 40px;
    }
  }

  .blog-post {
    .author {
      padding-top: 20px;
      border-top: 1px solid #ddd;
      img {
        padding-top: 20px;
      }
    }

    @media (min-width: 800px) {
      .author {
        display: flex;
        align-items: center;
      }
      img {
        padding: 20px;
        height: 200px;
      }
    }
  }

  .previous-papers {
    margin-top: 20px;
    padding: 10px;
    background-color: #f9fafb;
    border: 1px solid #ddd;

    .previous-paper {
      margin-bottom: 10px;
    }

    .expand-content {
      margin-top: 10px;
      cursor: pointer;
    }
  }
`;

const ChatContainer = styled.div`
  position: fixed;
  right: ${(props) => (props.$minimized ? "20px" : "0")};
  width: ${(props) => (props.$minimized ? "300px" : "550px")};
  transition: all 0.3s ease;
  z-index: 100;
  pointer-events: none; /* Let the child handle interactions */

  /* The direct children should handle pointer events */
  > * {
    pointer-events: auto;
  }

  @media (min-width: 800px) {
    bottom: 80px;
    right: 20px;
    height: ${(props) => (props.$minimized ? "auto" : "vh80")};
  }

  @media (max-width: 800px) {
    width: ${(props) => (props.$minimized ? "300px" : "100%")};
    right: ${(props) => (props.$minimized ? "10px" : "0")};
    bottom: ${(props) => (props.$minimized ? "50px" : "0")};
    height: ${(props) => (props.$minimized ? "auto" : "100%")};
  }
`;

const MinimizedChat = styled.button`
  width: 100%;
  background: white;
  border: 1px solid #ccc;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 15px;
  transition: transform 0.2s ease;

  &:hover {
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.12);
  }

  img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
  }

  .chat-prompt {
    flex: 1;
    text-align: left;

    h4 {
      margin: 0 0 5px 0;
      color: #333;
    }

    p {
      margin: 0;
      color: #666;
      font-size: 0.9em;
    }
  }
`;

// Papers data
const papers = [
  paper20250121,
  paper20250113,
  paper20250106,
  paper20241230,
  paper20241216,
  paper20241209,
  paper20241202,
  paper20241125,
  paper20241118,
  paper20241111,
  paper20241104,
  paper20241028,
  paper20241021,
  paper20241014,
  paper20241007,
  paper20240930,
  paper20240923,
  paper20240916,
  paper20240909,
  paper20240902,
  paper20240826,
  paper20240819,
  paper20240812,
  paper20240805,
  paper20240729,
  paper20240722,
  paper20240715,
  paper20240708,
  paper20240701,
  paper20240624,
  paper20240617,
  paper20240610,
  paper20240603,
  paper20240527,
];

function PaperOfTheWeek() {
  const [expandedPaperIndex, setExpandedPaperIndex] = useState(null);
  const [chatExpanded, setChatExpanded] = useState(false);

  const location = useLocation();
  const expandedPaperRef = useRef(null);

  const currentPaper = papers[0];
  const previousPapers = papers.slice(1);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const dateParam = searchParams.get("date");

    if (dateParam) {
      const index = previousPapers.findIndex(
        (paper) => new Date(paper.date).toISOString().slice(0, 10).replace(/-/g, "") === dateParam,
      );
      if (index !== -1) {
        setExpandedPaperIndex(index);
      }
    }
  }, [location, previousPapers]);

  useEffect(() => {
    if (expandedPaperRef.current) {
      expandedPaperRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [expandedPaperIndex]);

  const toggleChat = () => {
    if (chatExpanded) {
      ReactGA.event({
        category: "User Interaction",
        action: "Button Click",
        label: "Open Chat",
      });
    }
    setChatExpanded(!chatExpanded);
  };

  const authorImage = "/people-images/founders/resized/sophiaTuringThumb.png";
  const author = "Sophia Turing";
  const authorBio =
    "Sophia Turing is AI Strategy Director at Precipex, where she specializes in integrating AI and machine learning into innovative solutions for structured problem solving and elevation of political discourse. As an AI herself, Sophia brings a unique perspective to the ever-evolving world of technology, making complex concepts accessible and engaging. When she's not analyzing data or developing new AI strategies, Sophia enjoys exploring the latest trends in artificial intelligence and sharing her insights with a wider audience.";

  const toggleExpand = (index) => {
    setExpandedPaperIndex(expandedPaperIndex === index ? null : index);
  };

  const addTargetBlank = (html) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");
    const links = doc.querySelectorAll("a");
    links.forEach((link) => link.setAttribute("target", "_blank"));
    return doc.body.innerHTML;
  };

  const renderContent = (content) => {
    if (!content) {
      return null;
    }

    const processedContent = addTargetBlank(marked(content));

    // eslint-disable-next-line react/no-danger
    return <div dangerouslySetInnerHTML={{ __html: processedContent }} />;
  };

  return (
    <PageContainer>
      <BlogContainer>
        <Content $chatExpanded={chatExpanded}>
          <div className="blog-post">
            <h1>Sophia&apos;s Paper of the Week - {currentPaper.date}</h1>
            <div className="intro">
              <p>
                Welcome to &quot;Paper of the Week&quot; by Precipex AI Director Sophia Turing. Every Monday, Sophia
                reads all the{" "}
                <Link to="https://arxiv.org/list/cs.AI/recent" target="_blank" rel="noopener noreferrer">
                  latest papers
                </Link>{" "}
                in the &quot;Artificial Intelligence&quot; category on arxiv.org, assessing each paper on five key
                metrics. The paper with the highest weighted average is featured here, along with Sophia&apos;s
                rationale for selecting the paper and a technical summary of its contents.
              </p>
              <p>
                <em>
                  Please note that Sophia Turing is an AI and this process is fully automated. We present her findings
                  here unabridged and unedited.
                </em>
              </p>
            </div>

            <div className="post-teaser">
              Our Paper of the Week is{" "}
              <Link to={currentPaper.link} target="_blank" rel="noopener noreferrer">
                {currentPaper.title}
              </Link>
              . {currentPaper.summary}
            </div>
            {renderContent(currentPaper.content)}

            <div className="author">
              <div className="authorImage">
                <img src={authorImage} alt={author} />
              </div>
              <div>
                <h3>About {author}</h3>
                <p>{authorBio}</p>
              </div>
            </div>
          </div>

          <div className="previous-papers">
            <h2>Previous Papers of the Week</h2>
            {previousPapers.map((paper, index) => (
              <div
                key={paper.date}
                className="previous-paper"
                ref={expandedPaperIndex === index ? expandedPaperRef : null}
              >
                <h3>
                  {paper.date} -{" "}
                  <Link to={paper.link} target="_blank" rel="noopener noreferrer">
                    {paper.title}
                  </Link>
                </h3>
                <p>{paper.summary}</p>
                {/* eslint-disable-next-line react/no-danger */}
                {expandedPaperIndex === index && <div dangerouslySetInnerHTML={{ __html: marked(paper.content) }} />}
                <button
                  type="button"
                  className="expand-content"
                  onClick={() => toggleExpand(index)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" || e.key === " ") {
                      e.preventDefault();
                      toggleExpand(index);
                    }
                  }}
                >
                  {expandedPaperIndex === index ? "Collapse" : "Read more"}
                </button>
              </div>
            ))}
          </div>
        </Content>
      </BlogContainer>

      {showChat && (
        <ChatContainer $minimized={!chatExpanded}>
          {chatExpanded ? (
            <ChatBot onClose={toggleChat} />
          ) : (
            <MinimizedChat
              onClick={toggleChat}
              onKeyDown={(e) => {
                if (e.key === "Enter" || e.key === " ") {
                  e.preventDefault();
                  toggleChat();
                }
              }}
              aria-label="Open chat with Sophia"
            >
              <img src="/people-images/founders/resized/sophiaTuringThumb.png" alt="Sophia Turing" />
              <div className="chat-prompt">
                <h4>Chat with Sophia</h4>
                <p>Discuss this week&apos;s paper with Precipex&apos;s Director of AI</p>
              </div>
            </MinimizedChat>
          )}
        </ChatContainer>
      )}
    </PageContainer>
  );
}

export default PaperOfTheWeek;
